import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function SentimentGuide() {
  return (
    <Layout
      keywords="IrisAgent Ticket Sentiment guide"
      title="Explore Ticket Sentiment on your IrisAgent account"
      description="Documentation on how to use ticket sentiment feature on your IrisAgent account to understand the pulse of the customer."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/ticket-sentiment-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>IrisAgent Ticket Sentiment Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Ticket Sentiment Guide</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>IrisAgent Ticket Sentiment Guide</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent provides AI-powered sentiment analysis for actionable ticket sentiment and voice of customer. Our AI Sentiment Analysis solution provides instant visibility into the emotional tone of customer interactions.
                    <br/><br/>
                    Leveraging advanced natural language processing algorithms, machine learning, and opinion mining, our solution can identify positive or negative sentiment as they unfold, allowing your support team to respond promptly to customer needs. It adeptly identifies positive and negative sentiments in text data from customer interactions, offering insights that can drive product improvements and enhance customer satisfaction.
                    <br/>
                    <br/>
                    <h2>
                    Leveraging Ticket Sentiment in IrisAgent
                    </h2>
                    <br/>
                    <h3>View Ticket Sentiment on the Sidebar app</h3>
                    <br/>

                    Ticket Sentiment will be shown for all tickets in the sidebar app that you install in your ticketing systems, like Zendesk, Salesforce, etc. The sentiment score can be any of the following: Positive, Moderate Positive, Neutral, Moderate Negative, or Negative.

                    We allow fine-grained access control, so if you'd like to hide ticket sentiment widget for any agents, please let us know.
                    <br/><br/>
                    <img
                        src="/img/ticket-sentiment-widget.png"
                        style={{
                          width: "50%"
                        }}
                        alt="Ticket Deflection Toggle Switch"
                    /><br/><br/><br/>

                    <h3>View Ticket Sentiment on IrisAgent Dashboard for Top N Tickets</h3>
                    <br/>
                    Navigate to the <b>Needs Attention</b> page on our dashboard. On the <b>Cases that need attention</b> table, you can see the sentiment score and overall priority score for the top N problematic tickets. You can sort the tickets based on sentiment score to prioritize the tickets that need immediate attention.
                    <br/><br/>
                    <img
                        src="/img/cases-that-need-attention.png"
                        style={{
                          width: "90%"
                        }}
                        alt="Cases that need attention"
                    /><br/><br/><br/>


                    <h3>Write Ticket Sentiment to your Ticketing System</h3>
                    <br/>
                    For easier access to sentiment data, you can write the sentiment score to the tags field your ticketing system. This will help your agents to understand the customer's sentiment without having to navigate to the IrisAgent dashboard.<br/><br/>
                    <ol>
                        <li>Go to <b>Automation</b> --> <b>Triggers</b> page on the IrisAgent dashboard.</li>
                        <li>Click on <b>Create New Trigger</b> button.</li>
                        <li>Select a specific condition or add <b>Ticket Content</b> condition with empty value to enable for all tickets.</li>
                        <li>Under <b>Actions</b>, select <b>Write custom tag</b> action.</li>
                        <li>Enter the tag name as <b>&#123;&#123;ticket.sentiment&#125;&#125;</b> in the text box.</li>
                        <li>Click on <b>Add New Trigger</b> button.</li>

                    </ol>

                    <br/><br/>
                    <img
                        src="/img/ticket-sentiment-trigger.png"
                        style={{
                          width: "80%"
                        }}
                        alt="Ticket sentiment trigger"
                    /><br/><br/>

                    This will start writing the numerical value of Sentiment Score (between -100 and 100) to the "Tags" field in your ticketing system.

                    


<br/><br/>

Feel free to <a href="mailto:contact@irisagent.com" target="_blank">email us</a> if you encounter any issues or require assistance with product onboarding.
                    </div>
                    </div>
                    </section>

</Layout>
  )
}
